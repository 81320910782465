import React from "react"
import { Link } from "gatsby"
import SEO from "../../components/layout/seo"

import Layout from "../../components/layout/layout"
import ProductPage from "../../components/product/product"

import img1 from "../../images/solar-fan/7.jpg"

import solarFanSpecSheet from "../../images/solar-fan/specSheet.svg"

const SolarFanPage = () => {
  return (
    <Layout home={false}>
      <SEO title="Solar Fan" />
      <ProductPage
        productTitle="Solar Fan"
        showcase={
          <>
            <ul>
              <li>
                12v DC Fan that comes in operation mode of Zero, Low Medium &
                High
              </li>
              <li>
                Can be directly run through Solar Panel between 15 to 50 Watts
                or just plug and play through any 12 volts Battery
              </li>
              <li>Material of the Motor is pure copper</li>
              <li>
                Oscillation available from left to right (180 Degrees), up &
                down adjustable
              </li>
              <li>DC Table Fan is Made in India with speed of 1200 RPM</li>
              <li>Lithium Ion Battery Pack</li>
            </ul>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Link
                to="/contact-us"
                style={{
                  backgroundColor: "#203864",
                  marginTop: "1rem",
                  color: "white",
                  textDecoration: "none",
                  padding: "0.5rem 1rem",
                  fontFamily: "Helvetica",
                }}
              >
                Enquire Now
              </Link>
            </div>
          </>
        }
        showcaseImages={[img1]}
        description={
          <div style={{ width: "80%", lineHeight: "1.5rem" }}>
            <p style={{fontSize:"1.2rem"}}>
              High street lighting solar fans are one of the premium products in
              which the fan can be charged using a solar panel. The fan can act
              as an emergency equipment in case of no electricity. This fan is
              integrated with a lithium Ion battery and consumes less power.
            </p>
          </div>
        }
        technicalDetails={
          <div style={{ width: "180%", marginLeft: "-40%" }}>
            <img
              src={solarFanSpecSheet}
              style={{ width: "100%" }}
            />
          </div>
        }
      />
    </Layout>
  )
}

export default SolarFanPage
